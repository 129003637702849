import type { TypeNavigation } from './types';
import {
  ApartmentOutlined,
  AppstoreFilled,
  AuditOutlined,
  BarsOutlined,
  BookFilled,
  CalculatorOutlined,
  CloudFilled,
  ClusterOutlined,
  ContactsFilled,
  DatabaseFilled,
  ExperimentFilled,
  FundOutlined,
  HeatMapOutlined,
  KeyOutlined,
  SlidersFilled,
  UserOutlined,
  WalletFilled,
} from '@ant-design/icons';
import React from 'react';
import type { JsonProject } from 'src/store/retainers/projects/slice';
import type { JsonLoan } from 'src/store/loans/types';

// The ORDER of the actions in this array is IMPORTANT
// It defines the sorting order of the navigation items that user is authorized to.
// The order matters to get the default routes if the domain is undefined, then the first item is default
// if the domain is defined, then the first item of this domain is default
export const uiNavigationActions = [
  'read:loans:summary',
  'read:loans:overview',
  'read:loans:terms',
  'read:loans:balances',
  'read:loans:tasks',
  'read:loans:transactions',
  'read:loans:liquidity',
  'read:loans:trading',
  'read:loans:archive',
  'read:retainers:overview-admin',
  'read:retainers:overview',
  'read:retainers:terms-admin',
  'read:retainers:terms',
  'read:retainers:balances-admin',
  'read:retainers:balances',
  'read:retainers:tasks-admin',
  'read:retainers:tasks',
  'read:retainers:transactions',
  'read:retainers:trading',
  'read:retainers:arbitrage-info',
  'read:retainers:clients',
  'read:retainers:archive',
  'read:trading:tasks',
  'read:admin:servers',
  'read:admin:arbitrage',
  'read:admin:log',
  'read:admin:keys',
  'read:admin:users',
  'read:admin:roles',
  'read:admin:token-mapping',
];

interface GetNavigationConfigParams {
  currentLoan?: JsonLoan | null;
  currentRetainer?: JsonProject | null;
}

export const getNavigationConfig = ({
  currentLoan,
  currentRetainer,
}: GetNavigationConfigParams): TypeNavigation => {
  const loanIdParam = currentLoan ? `/${currentLoan.id}` : '';
  const retainerIdParam = currentRetainer ? `/${currentRetainer.id}` : '';

  return {
    admin: {
      servers: {
        key: `admin.servers`,
        label: 'Servers',
        icon: <ClusterOutlined />,
        url: '/admin/servers',
      },
      arbitrage: {
        key: `admin.arbitrage`,
        label: 'Arbitrage',
        icon: <FundOutlined />,
        url: '/admin/arbitrage',
      },
      log: {
        key: `admin.log`,
        label: 'Log',
        icon: <BarsOutlined />,
        url: '/admin/log',
      },
      keys: {
        key: `admin.keys`,
        label: 'Api Keys',
        icon: <KeyOutlined />,
        url: '/admin/keys',
      },
      users: {
        key: `admin.users`,
        label: 'Users',
        icon: <UserOutlined />,
        url: '/admin/users',
      },
      roles: {
        key: `admin.roles`,
        label: 'Roles',
        icon: <ApartmentOutlined />,
        url: '/admin/roles',
      },
      'token-mapping': {
        key: `admin.token-mapping`,
        label: 'Token Mapping',
        icon: <HeatMapOutlined />,
        url: '/admin/token-mapping',
      },
    },
    loans: {
      summary: {
        key: `loans.summary`,
        label: 'Summary',
        icon: <CalculatorOutlined />,
        url: '/loans/summary',
      },
      overview: {
        key: `loans.overview`,
        label: 'Overview',
        icon: <BookFilled />,
        url: `/loans/overview${loanIdParam}`,
      },
      terms: {
        key: `loans.terms`,
        label: 'Loan Terms',
        icon: <DatabaseFilled />,
        url: `/loans/terms${loanIdParam}`,
      },
      balances: {
        key: `loans.balances`,
        label: 'Balances',
        icon: <AppstoreFilled />,
        url: `/loans/balances${loanIdParam}`,
      },
      tasks: {
        key: `loans.tasks`,
        label: 'Tasks',
        icon: <AppstoreFilled />,
        url: `/loans/tasks${loanIdParam}`,
      },
      transactions: {
        key: `loans.transactions`,
        label: 'Transactions',
        icon: <WalletFilled />,
        url: `/loans/transactions${loanIdParam}`,
      },
      liquidity: {
        key: `loans.liquidity`,
        label: 'Liquidity',
        icon: <ExperimentFilled />,
        url: `/loans/liquidity${loanIdParam}`,
      },
      trading: {
        key: `loans.trading`,
        label: 'Trading',
        icon: <SlidersFilled />,
        url: `/loans/trading${loanIdParam}`,
      },
      archive: {
        key: `loans.archive`,
        label: 'Archive',
        icon: <CloudFilled />,
        url: `/loans/archive`,
      },
    },
    retainers: {
      'overview-admin': {
        key: `retainers.overview-admin`,
        label: 'Overview',
        icon: <BookFilled />,
        url: `/retainers/overview-admin${retainerIdParam}`,
      },
      'terms-admin': {
        key: `retainers.terms-admin`,
        label: 'Project Terms',
        icon: <DatabaseFilled />,
        url: `/retainers/terms-admin${retainerIdParam}`,
      },
      'balances-admin': {
        key: `retainers.balances-admin`,
        label: 'Balances',
        icon: <AppstoreFilled />,
        url: `/retainers/balances-admin${retainerIdParam}`,
      },
      'tasks-admin': {
        key: `retainers.tasks-admin`,
        label: 'Tasks',
        icon: <AppstoreFilled />,
        url: `/retainers/tasks-admin${retainerIdParam}`,
      },
      transactions: {
        key: `retainers.transactions`,
        label: 'Transactions',
        icon: <WalletFilled />,
        url: `/retainers/transactions${retainerIdParam}`,
      },
      trading: {
        key: `retainers.trading`,
        label: 'Trading',
        icon: <SlidersFilled />,
        url: `/retainers/trading${retainerIdParam}`,
      },
      'arbitrage-info': {
        key: `retainers.arbitrage-info`,
        label: 'Arbitrage Info',
        icon: <AuditOutlined />,
        url: `/retainers/arbitrage-info${retainerIdParam}`,
      },
      clients: {
        key: `retainers.clients`,
        label: 'Clients',
        icon: <ContactsFilled />,
        url: `/retainers/clients${retainerIdParam}`,
      },
      archive: {
        key: `retainers.archive`,
        label: 'Archive',
        icon: <CloudFilled />,
        url: `/retainers/archive`,
      },
    },
    'retainers:client': {
      overview: {
        key: 'retainers.overview',
        label: 'Overview',
        icon: <BookFilled />,
        url: `/retainers/overview${retainerIdParam}`,
      },
      terms: {
        key: `retainers.terms`,
        label: 'Project Terms',
        icon: <DatabaseFilled />,
        url: `/retainers/terms${retainerIdParam}`,
      },
      balances: {
        key: `retainers.balances`,
        label: 'Balances',
        icon: <AppstoreFilled />,
        url: `/retainers/balances${retainerIdParam}`,
      },
      tasks: {
        key: `retainers.tasks`,
        label: 'Tasks',
        icon: <AppstoreFilled />,
        url: `/retainers/tasks${retainerIdParam}`,
      },
    },
    trading: {
      dashboard: {
        key: 'trading.tasks',
        label: 'Dashboard',
        icon: <BookFilled />,
        url: `/trading/tasks`,
      },
    },
  };
};
