import { createArchiveSlice } from 'src/store/shared/archive/factory';
import { searchArchivedLoans } from 'src/store/loans/actions';

export enum LoanArchiveCommands {
  ArchivedLoans = 'ArchivedLoans',
  SearchArchivedLoans = 'SearchArchivedLoans',
  ShowLoan = 'ShowLoan',
}

const slice = createArchiveSlice({
  prefix: 'loans',
  commands: {
    ArchivedItems: LoanArchiveCommands.ArchivedLoans,
    SearchArchivedItems: LoanArchiveCommands.SearchArchivedLoans,
    ShowItem: LoanArchiveCommands.ShowLoan,
  },
  searchAction: searchArchivedLoans,
});

export const loansArchiveActions = slice.actions;
export default slice.reducer;
