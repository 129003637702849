export enum ServerModeCommands {
  ServerMode = 'server-mode',
}

export enum ServerModes {
  ReadWrite = 'read-write',
  ReadOnly = 'read-only',
}

export enum ServerPrefixes {
  MM = 'MM',
  ES = 'ES',
}

export type ServerMode = ServerModes.ReadWrite | ServerModes.ReadOnly;

export type ServerPrefix = ServerPrefixes.MM | ServerPrefixes.ES;
