import { createAction } from '@reduxjs/toolkit';

const routerActionPrefix = 'router';

export interface RouterHistoryActionPayload {
  path: string;
}

export const routerHistoryChange = createAction(`${routerActionPrefix}/change`, (path: string) => ({
  payload: { path },
}));
