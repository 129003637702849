import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { skyRouter } from 'src/routes/sky-router';
import { ApplicationDomain } from 'src/routes/types';
import type { RequestStatus } from 'src/store/types';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage, wsLoansSend } from 'src/store/ws-loans/actions';
import type { RootState } from 'src/store';
import { selectAuthDomain, selectUserToken } from 'src/store/auth/selectors';
import { selectAccessToken } from 'src/store/signup/selectors';
import { getTokenPayload } from 'src/store/shared/rtkAuthApi/helpers';
import { AuthCommands } from './types';
import { logout } from './actions';
import { AUTH_BASE_URL } from 'src/constants';
import { AuthApiEndpoints } from '../shared/rtkAuthApi/types';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { requiresLoginCommand } from 'src/utils/routing/validation';
import { wsEsSend } from 'src/store/trading/actions';

const allowedCommands = Object.values(AuthCommands);

interface LoginDomainPayload {
  accessToken: string;
  token?: string;
  loan?: number;
  retainer?: number;
}

export interface AuthState {
  error: null | {
    title: string;
    description?: string;
  };
  redirectUrl: string;
  resAuth?: RequestStatus;
  res2FA?: RequestStatus;

  // from auth response
  token?: string;
  name?: string;
  email?: string;
  lastAccess?: string; // Friday, 17/02/2023
  accessLevel?: number; // enum?
  userId?: number;
  notConnected?: boolean;
  authDomain?: string; // domain wich user is authenticated
}

const initialState: AuthState = {
  error: null,
  redirectUrl: '/',
  resAuth: null,
  res2FA: null,

  // token: null,
  // name: '',
  // userId,
};

interface JsonAuthSuccess {
  command: string;

  // success
  token: string;
  Name: string;
  lastAccess: string;
  accessLevel: number;
  userID: number;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRedirectUrl(state, action: PayloadAction<{ url: string }>) {
      state.redirectUrl = action.payload.url;
    },
    setAuthDomain(state, action: PayloadAction<{ domain: string }>) {
      state.authDomain = action.payload.domain;
    },
  },
  extraReducers: (builder) => {
    // logout

    builder.addCase(logout.fulfilled, (state, action) => {
      // Add user to the state array
      console.debug('auth/logout', action.payload);
      return initialState;
    });

    // builder.addCase(actionSend, (state, action) => {
    //   console.debug(`auth/processing ${actionSend}`, action);
    // });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`auth/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        if (errorCommand === AuthCommands.Authorization) {
          state.resAuth = 'error';
        }
        if (errorCommand === AuthCommands['2FA']) {
          state.res2FA = 'error';
        }
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          case AuthCommands.Authorization:
            state.error = null;
            state.resAuth = 'success';
            return;

          default:
            break;
        }
      }

      if (command === AuthCommands.authOK) {
        const { token, Name = '', accessLevel, lastAccess /* , userID */ }: JsonAuthSuccess = json;

        state.error = null;
        state.res2FA = 'success';

        state.token = token;

        state.name = Name;
        // state.userId = userID || 0;
        state.lastAccess = lastAccess;
        state.accessLevel = accessLevel;
        return;
      }

      if (command === AuthCommands.NotConnected) {
        state.notConnected = true;
        state.authDomain = undefined;
        return;
      }

      if (command === AuthCommands.authRequired) {
        state.token = undefined;
      }
    });
  },
});

function getLoginExtra(domain?: string) {
  switch (domain) {
    case ApplicationDomain.Loans:
    case ApplicationDomain.Profile:
    case ApplicationDomain.Admin:
      return { loan: 1 };
    case ApplicationDomain.Retainers:
      return { retainer: 1 };
    case ApplicationDomain.Trading:
      return { trading: 1 };
    default:
      return null;
  }
}

interface LoginDomainData {
  domain: ApplicationDomain;
  forceLogin?: boolean;
}

export const loginDomain = createAsyncThunk(
  'auth/login',
  async ({ domain, forceLogin = false }: LoginDomainData, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const token = selectUserToken(state);
    const accessToken = selectAccessToken(state);
    const authDomain = selectAuthDomain(state);

    if (authDomain === domain && !forceLogin) {
      return;
    }

    const extra = getLoginExtra(domain);

    if (!accessToken || !extra) {
      return;
    }
    const { isMfaAuthenticated, isTokenExpired } = getTokenPayload(accessToken);
    const isAccessTokenValid = accessToken && isMfaAuthenticated && !isTokenExpired; // && !token;
    if (!isAccessTokenValid) {
      await fetch(AUTH_BASE_URL + AuthApiEndpoints.RefreshToken, {
        method: 'POST',
        credentials: 'include',
      });
      // return;
    }

    // as per backend request we should send accessToken always now
    const payload: LoginDomainPayload = {
      ...(token && { token }),
      ...{ accessToken },
      ...extra,
    };

    thunkApi.dispatch(authSlice.actions.setAuthDomain({ domain }));

    if (domain === ApplicationDomain.Trading) {
      return thunkApi.dispatch(
        wsEsSend({
          command: AuthCommands.Login,
          ...payload,
        }),
      );
    }

    thunkApi.dispatch(
      wsLoansSend({
        command: AuthCommands.Login,
        ...payload,
      }),
    );
  },
);

export const authActions = authSlice.actions;

export default authSlice.reducer;

startListening({
  actionCreator: actionOnMessage,
  effect: async (action: PayloadAction<{ message: string }>, listenerApi) => {
    const json = JSON.parse(action.payload.message) as { command: string };
    if (json.command === (AuthCommands.authRequired as string)) {
      const state = listenerApi.getState();
      const domain = (state.auth.authDomain ??
        skyRouter.getDomain() ??
        ApplicationDomain.Loans) as ApplicationDomain;
      const pathname = skyRouter.getPathname();

      if (requiresLoginCommand(pathname)) {
        await listenerApi.dispatch(loginDomain({ domain, forceLogin: true }));
      }
    }
  },
});
