import includes from 'lodash/includes';
import { IS_AUTHZ_NAVIGATION_ENABLED } from 'src/constants';
import type { MenuItem } from 'src/sky-ui-lib/SkyAccMenu/types';
import type { SubMenuType } from 'antd/es/menu/interface';
import type { ApplicationDomain } from 'src/routes/types';
import { useCheckNavigationAccess } from './useCheckNavigationAccess';

const mapNavItemToAction = (navItem: SubMenuType) => {
  return `read:${(navItem?.key ?? '').replace('.', ':')}`;
};

export const getCerbosNavigationAction = (domain: ApplicationDomain, section: string) =>
  `read:${domain.toLowerCase()}:${section}`;

/**
 * Hook to filter out navigation items based on the user's permissions
 */
export const useAuthorizedNavigation = () => {
  const { data, isLoading } = useCheckNavigationAccess();
  const keepAllowedPages = (navItems: MenuItem[]): MenuItem[] => {
    if (!IS_AUTHZ_NAVIGATION_ENABLED) {
      return navItems;
    }

    return navItems.filter((navItem) => {
      if (!navItem?.key) {
        return true;
      }

      const entry = navItem as SubMenuType;

      if (entry.children) {
        const initialChildrenCount = entry.children?.length;
        entry.children = keepAllowedPages(entry.children);
        if (initialChildrenCount > 0 && entry.children.length === 0) {
          // Top level menu item should not be shown if all children are hidden
          return false;
        }
      }

      return Boolean(entry.children?.length) || includes(data, mapNavItemToAction(entry));
    });
  };

  return {
    isLoading,
    keepAllowedPages,
  };
};
