import type { ActualDepth, DepthDataMap, JsonLinechartOverview } from 'src/store/loans/types';
import type { MarketVolumesItem, TradingVolumeItem } from 'src/store/loans/exchanges/types';
import type { JsonBalance } from 'src/store/shared/balances/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { LiquidityMap } from 'src/store/shared/kpi/types';

export enum RetainerCommands {
  Listen = 'Listen',
  RetainerOverview = 'RetainerOverview',
  HideRetainer = 'HideRetainer',
  Projects = 'Projects',
  // SetInitialBalance = 'SetInitialBalance',

  ArchivedRetainers = 'ArchivedRetainers',
  SearchArchivedRetainers = 'SearchArchivedRetainers',
  ShowRetainer = 'ShowRetainer',
}

export interface JsonRetainersExchange {
  exchangeID: number;
  pair: string;
  lastPrice: number;
  averageSpread: number;
  ourSpread: number;
  tradingVolumes: TradingVolumeItem[];
  ourLiquidity: LiquidityMap;
  marketVolumes: MarketVolumesItem[];
  marketLiquidity: LiquidityMap;
  actualLiquidity: Record<ActualDepth, number>;
}

export interface AssetInfo {
  token: string;
  volume: number;
  lastPrice: number;
}

export interface JsonRetainersExchangeExtended extends JsonRetainersExchange {
  currentLiquidity: Record<ActualDepth, number>;

  avgLiquidityByDate: Record<ActualDepth, [string, number][]>;

  baseToken: AssetInfo;
  otherTokens: AssetInfo[];

  stables: number;
  exchange: JsonExchange;
  depthData: DepthDataMap;

  tradingVolumeLastLocal: number;
  tradingVolumePrevLocal: number;
  tradingVolumeDiffLocal: number;
  tradingVolumeByDateLocal: [string, number][];
  marketVolume: number;
  marketVolumeByDate: [string, number][];
  volumeShareLocal: number;
  volumeShareByDateLocal: [string, number][];

  tradingVolumeLastUTC: number;
  tradingVolumePrevUTC: number;
  tradingVolumeDiffUTC: number;
  tradingVolumeByDateUTC: [string, number][];
  volumeShareUTC: number;
  volumeShareByDateUTC: [string, number][];
  marketDepthData: DepthDataMap;
}

export interface JsonRetainersExchangesSuccess {
  command: RetainerCommands.RetainerOverview;
  exchanges: JsonRetainersExchange[];
  balances: JsonBalance[];
  linechartWeek: JsonLinechartOverview;
  linechartYear: JsonLinechartOverview;
  pairs: string[];
  supportedExchanges: number[];
}

export interface JsonRetainersStats {
  baseTokenTotal: number;
  otherTokensTotal: number;
  lastPriceTotal: number;
  lastPriceCount: number;
  baseTokenTotalVolume: number;
  baseToken: string;
}

export interface JsonRetainersPiechartData {
  id: string;
  label: string;
  value: number;
  percentageValue: number;
  color: string;
}

export interface RetainersSunburstData {
  baseToken: AssetInfo;
  otherTokens: AssetInfo[];
  id: string;
  exchange: string;
}
