import { LoanCommands } from 'src/store/loans/types';
import { StrikePricesCommands } from 'src/store/loans/strike-prices/types';
import { RetainerKpiCommands } from 'src/store/retainers/kpi/slice';
import { LoanExchangesCommands } from 'src/store/loans/exchanges/types';
import { LoanFundsCommands } from 'src/store/loans/funds/types';
import { LoanProfitCommands } from 'src/store/loans/profit/types';
import { LoanStatsCommands } from 'src/store/loans/stats/types';
import { AdminPanelCommands } from 'src/store/retainers/admin-panel/types';
import { TransactionCommands } from 'src/store/shared/transactions/types';
import { AccountCommands } from 'src/store/shared/accounts/types';
import { KpiCommands } from 'src/store/shared/kpi/types';
import { RetainerCommands } from 'src/store/retainers/types';
import { ExchangeCommands } from 'src/store/shared/exchanges/types';
import { ReportCommands } from 'src/store/reports/types';
import { LoanTradingViewCommands } from 'src/store/loans/trading-view/types';
import { LoanAccountThresholdCommands } from 'src/store/loans/threshold/slice';
import { LoanKpiCommands } from 'src/store/loans/kpi/slice';
import { TradingCommands } from 'src/store/shared/trading/types';
import { RetainerTermsCommands } from 'src/store/retainers/terms/types';
import { LoanTermsCommands } from 'src/store/loans/terms/types';
import { ArbitrageInfoCommands } from 'src/store/shared/arbitrage-info/types';
import { TaskCommands } from 'src/store/shared/tasks/actions';
import { BalanceCommands } from 'src/store/shared/balances/types';
import { BalanceHistoryCommands } from 'src/store/shared/balance-history/types';
import { LoansSummaryCommands } from 'src/store/loans/summary/types';
import { ArbitrageCommands } from 'src/store/arbitrage/types';
import { AdminCommands } from 'src/store/admin/types';
import { ApiCommands } from 'src/store/apiKeys/types';
import { TradingApiCommands } from 'src/store/trading/apiKeys/types';
import { LogsCommand } from 'src/store/shared/log/types';

export enum CerbosActions {
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Create = 'create',
  Validate = 'validate',
  Unlink = 'unlink',
  Block = 'block',
  Unblock = 'unblock',
  Bind = 'bind',
  Unbind = 'unbind',
}

export type CerbosActionType = `${CerbosActions}` | `${CerbosActions}:${string}`;

export enum UINavigation {
  'UINavigation' = 'UINavigation',
}

export enum DexApiResources {
  TokenMapping = 'TokenMapping',
  Wallet = 'Wallet',
}

export enum AuthApiResources {
  User = 'User',
  Role = 'Role',
}

export enum BugReportResource {
  UIBugReport = 'UIBugReport',
}

export enum ProfileResource {
  Profile = 'Profile',
}

export const CombineCommands = {
  ...StrikePricesCommands,
  ...RetainerKpiCommands,
  ...LoanExchangesCommands,
  ...LoanFundsCommands,
  ...LoanProfitCommands,
  ...KpiCommands,
  ...LoanStatsCommands,
  ...AdminPanelCommands,
  ...TransactionCommands,
  ...AccountCommands,
  ...RetainerCommands,
  ...ExchangeCommands,
  ...ReportCommands,
  ...LoanTradingViewCommands,
  ...LoanKpiCommands,
  ...LoanAccountThresholdCommands,
  LoanKpi: LoanKpiCommands.KPI,
  RetainersKpi: RetainerKpiCommands.KPI,
  ...UINavigation,
  ...TradingCommands,
  ...RetainerTermsCommands,
  ...LoanCommands,
  LoansTerms: LoanTermsCommands.Terms,
  ...ArbitrageInfoCommands,
  ...TaskCommands,
  ...BalanceCommands,
  ...BalanceHistoryCommands,
  ...ArbitrageCommands,
  ...LoansSummaryCommands,
  ...AdminCommands,
  ...ApiCommands,
  ...DexApiResources,
  ...AuthApiResources,
  ...BugReportResource,
  ...ProfileResource,
  ...TradingApiCommands,
  ...LogsCommand,
};

export type CerbosResource = (typeof CombineCommands)[keyof typeof CombineCommands];

export const ANY_ID = '*';

export interface CerbosIsAllowed {
  resource: {
    id: string;
    kind: CerbosResource;
    scope?: string;
    attr?: Record<string, string>;
  };
  action: CerbosActionType;
}

export interface CerbosCheckResource {
  resource: {
    id: string;
    kind: CerbosResource;
  };
  actions: CerbosActionType[];
}

export interface CerbosCheckResources {
  resources: CerbosCheckResource[];
}
