import type { Location } from 'react-router';
import { useLocation } from 'react-router';

import capitalize from 'lodash/capitalize';

import { ApplicationDomain } from 'src/routes/types';

import { useState } from 'react';
import { useCheckNavigationAccess } from 'src/hooks/useCerbos/useCheckNavigationAccess';
import values from 'lodash/values';
import { getCerbosNavigationAction } from 'src/hooks/useCerbos/useAuthorizedNavigation';
import includes from 'lodash/includes';

// [ApplicationDomain.values without Profile]: Recordc<string, string>
export type SectionConfig = {
  [key in ApplicationDomain as ApplicationDomain extends Exact<key, ApplicationDomain.Profile>
    ? never
    : key]: Record<string, string>;
};

const domainsWithSections = [
  ApplicationDomain.Admin,
  ApplicationDomain.Loans,
  ApplicationDomain.Retainers,
  ApplicationDomain.Trading,
];

const navKeys: SectionConfig = {
  [ApplicationDomain.Admin]: {
    servers: 'servers',
    arbitrage: 'arbitrage',
    log: 'log',
    keys: 'keys',
    users: 'users',
    roles: 'roles',
    'token-mapping': 'token-mapping',
  },
  [ApplicationDomain.Loans]: {
    summary: 'summary',
    archive: 'archive',
    overview: 'overview',
    exchanges: 'overview',
    balances: 'balances',
    tasks: 'tasks',
    transactions: 'transactions',
    liquidity: 'liquidity',
    trading: 'trading',
    terms: 'terms',
  },
  [ApplicationDomain.Retainers]: {
    overview: 'overview',
    terms: 'terms',
    // exchanges: 'overview',
    balances: 'balances',
    trading: 'trading',
    'arbitrage-info': 'arbitrage-info',
    // bots: 'bots',
    tasks: 'tasks',
    transactions: 'transactions',
    'balances-admin': 'balances-admin',
    'overview-admin': 'overview-admin',
    'tasks-admin': 'tasks-admin',
    clients: 'clients',
    'terms-admin': 'terms-admin',
    archive: 'archive',
  },
  [ApplicationDomain.Trading]: {
    tasks: 'tasks',
  },
};

const noIdSections: Record<ApplicationDomain, string[]> = {
  [ApplicationDomain.Loans]: ['summary', 'not-found', 'archive'],
  [ApplicationDomain.Retainers]: ['not-found', 'archive'],
  [ApplicationDomain.Admin]: ['not-found'],
  [ApplicationDomain.Profile]: ['not-found'],
  [ApplicationDomain.Trading]: ['not-found'],
};

/**
 *
 * @param allowedPages Authorization data from Cerbos. Array of the keys (e.g. 'read:loans:summary')
 * @param presetDomain Should be passed if we want to get the default section for the specific domain
 * @returns [ApplicationDomain, string | undefined | null] First item – ApplicationDomain is set considering the data from authorization, second item – section could be null, if the domain has no sections (e.g. Profile)
 */
export function getDefaults(
  allowedPages: string[],
  presetDomain?: ApplicationDomain,
): [ApplicationDomain, string | null] {
  const domainsToCheck = [
    ApplicationDomain.Loans,
    ApplicationDomain.Retainers,
    ApplicationDomain.Admin,
  ] as const;

  // Checks domains and sections from the navKeys config and returns the first allowed domain/section pair.
  // If nothing is allowed, returns undefined for both domain and section.
  const allowedDefaults = domainsToCheck.reduce(
    (acc, domain) => {
      if (acc[0] && acc[1]) {
        return acc;
      }

      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (acc[0] === undefined) {
        acc[0] = domain;
      }

      if (!domainsWithSections.includes(acc[0])) {
        acc[1] = null;
        return acc;
      }

      const sections = values(navKeys[acc[0] as keyof SectionConfig]);
      const allowedSection = sections.find((section) => {
        const authzAction = getCerbosNavigationAction(acc[0] as keyof SectionConfig, section);
        return includes(allowedPages, authzAction);
      });

      if (allowedSection) {
        acc[1] = allowedSection;
      } else {
        acc[1] = undefined; // No sections allowed for this domain
        acc[0] = undefined; // Current domain is not allowed, as auth data does not contain any allowed sections of this domain.
      }

      return acc;
    },
    [presetDomain, undefined] as [ApplicationDomain | undefined, string | undefined | null],
  );

  if (allowedDefaults[0] === undefined) {
    // If no domain is allowed, return Profile as allowed domain
    return [ApplicationDomain.Profile, null];
  }

  return allowedDefaults as [ApplicationDomain, string | null];
}

export function isNoIdSection(domain: ApplicationDomain, section?: string | null): boolean {
  if (!section) {
    return true;
  }
  return Boolean(noIdSections[domain]?.includes(section));
}

export interface GetSectionParams {
  domain: keyof typeof navKeys;
  location: Location;
}

export function getSection({ domain, location }: GetSectionParams): string {
  const parts = location.pathname.split('/').filter(Boolean);
  const sections = navKeys[domain];
  const sectionKey = parts[1]?.toLowerCase();

  switch (domain) {
    case ApplicationDomain.Admin:
    case ApplicationDomain.Loans:
    case ApplicationDomain.Retainers:
    case ApplicationDomain.Trading: {
      return sections[sectionKey] ?? '';
    }
    default:
      return '';
  }
}

interface SectionProps {
  domain?: ApplicationDomain;
}

interface SectionData {
  isLoading: boolean;
  domain?: ApplicationDomain;
  section?: string | null;
  isNoIdSection?: boolean;
}

export function useSection({ domain: presetDomain }: SectionProps = {}): SectionData {
  const [domain, setDomain] = useState<ApplicationDomain | undefined>();
  const [section, setSection] = useState<string | undefined | null>();
  const location = useLocation();

  const { data, isLoading } = useCheckNavigationAccess();

  const [urlDomain] = location.pathname.split('/').filter(Boolean);

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const domainKey = capitalize(presetDomain || urlDomain) as ApplicationDomain;
  if (!domainKey || !Object.values(ApplicationDomain).includes(domainKey)) {
    const [defaultDomain, defaultSection] = getDefaults(data ?? []);

    if (defaultDomain !== domain) {
      setDomain(defaultDomain);
    }
    if (defaultSection !== section) {
      setSection(defaultSection);
    }
  } else if (!domainsWithSections.includes(domainKey)) {
    if (domainKey !== domain) {
      setDomain(domainKey);
      setSection(null);
    }
  } else {
    const sections = navKeys[domainKey as keyof typeof navKeys];
    const sectionByLocation = sections
      ? getSection({ domain: domainKey as keyof typeof navKeys, location })
      : undefined;
    if (sectionByLocation !== section) {
      setSection(sectionByLocation);
    }
    if (domainKey !== domain) {
      setDomain(domainKey);
    }
  }

  return {
    section,
    domain,
    isLoading,
    isNoIdSection: isNoIdSection(domainKey, section),
  };
}
