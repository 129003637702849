import type { Store } from 'redux';
import type { RootState } from 'src/store';
import { skyCerbosClient } from 'src/sky-ui-lib/SkyAuthz/skyCerbosClient';
import { UINavigationCheck } from 'src/utils/cerbos/UINavigation';
import { selectJwtPrincipal } from 'src/store/signup/selectors';
import { selectNavigation } from 'src/store/navigation/selector';
import { skyRouter } from 'src/routes/sky-router';
import { navigationPermissionsActions } from 'src/store/navigation/slice';
import type { Principal } from 'src/sky-ui-lib/SkyAuthz/getPrincipal';
import { uiNavigationActions } from 'src/routes/navigation-config';

let prevUrl = '';

interface CheckAccessUiNavigationProps {
  store: Store;
  url?: URL;
  principal?: Principal;
}

export const getUiNavigationAuthzActions = async (principal: Principal): Promise<string[]> => {
  const resource = await skyCerbosClient.checkResource({
    principal,
    ...UINavigationCheck, // Ensure UINavigationCheck is defined
  });

  // sort result the same order as uiNavigationActions to get the default routes
  return resource
    .allowedActions()
    .sort((a, z) => uiNavigationActions.indexOf(a) - uiNavigationActions.indexOf(z));
};

export const checkAccessUiNavigation = async ({
  store,
  url,
  principal,
}: CheckAccessUiNavigationProps) => {
  const state = store.getState() as RootState;
  const data = selectNavigation(state);
  const prevDomain = skyRouter.getDomain(prevUrl);

  if (prevDomain === skyRouter.getDomain(url)) {
    prevUrl = skyRouter.getPathname(url);
  }

  if (prevUrl === skyRouter.getPathname(url) && data) {
    return data;
  }

  const storePrincipal = selectJwtPrincipal(state);

  navigationPermissionsActions.setLoading('pending');

  const allowedActions = await getUiNavigationAuthzActions(principal ?? storePrincipal);

  store.dispatch(
    navigationPermissionsActions.setData({
      data: allowedActions,
    }),
  );

  prevUrl = skyRouter.getPathname(url);
  return allowedActions;
};
