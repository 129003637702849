import * as Sentry from '@sentry/react';
import type { DataStrategyFunctionArgs, RouteObject } from 'react-router';
import { createBrowserRouter } from 'react-router';
import capitalize from 'lodash/capitalize';
import type { RootHandle } from './config';
import type { ApplicationDomain } from './types';
import { SkyRouterAction } from './types';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

function getPathname(url: string | URL) {
  if (typeof url === 'string') {
    if (url.startsWith('http')) {
      const urlObject = URL.parse(url);
      return urlObject?.pathname ?? '';
    }
    return url;
  }
  const urlObject = typeof url === 'string' ? new URL(url) : url;
  return urlObject.pathname;
}

class SkyRouter {
  router: ReturnType<typeof sentryCreateBrowserRouter> | null;

  constructor() {
    this.router = null;
  }

  init(routerConfig: RouteObject[], onHistoryChange: (pathname: string) => void) {
    if (this.router) {
      return this.router;
    }
    this.router = sentryCreateBrowserRouter(routerConfig, {
      async dataStrategy({ request, params, matches }: DataStrategyFunctionArgs) {
        const context = {};
        for (const match of matches) {
          if ((match.route.handle as RootHandle)?.middleware) {
            await (match.route.handle as RootHandle).middleware(
              {
                request,
                params,
                context: undefined,
              },
              context,
            );
          }
        }

        // Run loaders in parallel with the `context` value
        const matchesToLoad = matches.filter((m) => m.shouldLoad);
        const results = await Promise.all(
          matchesToLoad.map((match) =>
            match.resolve((handler) => {
              // Whatever you pass to `handler` will be passed as the 2nd parameter
              // to your loader/action
              return handler(context);
            }),
          ),
        );
        return results.reduce(
          (acc, result, i) =>
            Object.assign(acc, {
              [matchesToLoad[i].route.id]: result,
            }),
          {},
        );
      },
    });

    this.router.subscribe((state) => {
      const { historyAction } = state;
      const action = historyAction as unknown as SkyRouterAction;
      switch (action) {
        case SkyRouterAction.Pop:
        case SkyRouterAction.Push:
        case SkyRouterAction.Replace:
          onHistoryChange(state.location.pathname);
          break;
        default:
          break;
      }
    });

    return this.router;
  }

  getDomain(url?: string | URL): ApplicationDomain | undefined {
    let domainPath;

    if (url) {
      [domainPath] = getPathname(url).split('/').filter(Boolean);
    } else {
      [domainPath] = this.router?.state.location.pathname.split('/').filter(Boolean) ?? [];
    }
    return domainPath ? (capitalize(domainPath) as ApplicationDomain) : undefined;
  }

  getSection(url?: string | URL) {
    if (url) {
      return getPathname(url).split('/').filter(Boolean)[1];
    }
    return this.router?.state.location.pathname.split('/').filter(Boolean)[1];
  }

  getEntityId(url?: string | URL) {
    if (url) {
      return getPathname(url).split('/').filter(Boolean)[2];
    }
    return this.router?.state.location.pathname.split('/').filter(Boolean)[2];
  }

  getPathname(url?: string | URL) {
    if (url) {
      return getPathname(url);
    }
    return this.router?.state.location.pathname ?? '';
  }
}

export const skyRouter = new SkyRouter();
