import type { Middleware } from '@reduxjs/toolkit';
import reduxWebsocket from '../middleware/redux-websocket';

export const prefix = 'ES_WS';

const wsEsMiddleware: Middleware = reduxWebsocket({
  prefix,
  reconnectOnClose: false,
  dateSerializer: (date: Date) => date.toISOString(),
});

export default wsEsMiddleware;
