import { UINavigation, ANY_ID } from 'src/utils/cerbos/type';
import type { CerbosCheckResource } from 'src/utils/cerbos/type';
import { uiNavigationActions } from 'src/routes/navigation-config';

type UINavigationCheckType = Omit<CerbosCheckResource, 'actions'> & {
  actions: typeof uiNavigationActions;
};

export const UINavigationCheck: UINavigationCheckType = {
  resource: {
    kind: UINavigation.UINavigation,
    id: ANY_ID,
  },
  actions: uiNavigationActions,
};
