import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  actionEsConnect,
  actionEsDisconnect,
  actionEsOnClose,
  actionEsOnClosed,
  actionEsOnError,
  actionEsOnMessage,
  actionEsOnOpen,
  actionEsOnReconnectBegin,
  actionEsSend,
} from './actions';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { signUpApi } from 'src/store/signup/actions';
import { ensureAccessToken } from 'src/store/shared/rtkAuthApi/refresh-auth';
import { selectAccessToken } from 'src/store/signup/selectors';
import { checkAccessUiNavigation } from 'src/utils/cerbos/checkAccessUiNavigation';
import type { Store } from 'redux';
import { getDefaults } from 'src/hooks/useSection';
import { skyRouter } from 'src/routes/sky-router';
import { ApplicationDomain } from 'src/routes/types';
import { authActions, loginDomain } from 'src/store/auth/slice';
import { requiresLoginCommand } from 'src/utils/routing/validation';
import { selectWsEsStatus } from 'src/store/trading/selectors';

export interface WsEsState {
  status: 'idle' | 'connecting' | 'disconnecting' | 'connected';
  error: object | null;
  isReconnecting: boolean;
}

const initialState: WsEsState = {
  status: 'idle',
  error: null,
  isReconnecting: false,
};

const wsEsSlice = createSlice({
  name: 'wsEs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionEsConnect, (state, action) => {
      console.log(`${actionEsConnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsConnect.toString()}`, action);
      if (state.status === 'connected') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionEsOnReconnectBegin, (state, action) => {
      console.log(`${actionEsOnReconnectBegin.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsOnReconnectBegin.toString()}`, action);
      if (state.status === 'connected' || state.status === 'idle') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionEsDisconnect, (state, action) => {
      console.log(`${actionEsDisconnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsDisconnect.toString()}`, action);
      state.status = 'disconnecting';
    });

    builder.addCase(actionEsOnOpen, (state, action) => {
      console.log(`${actionEsOnOpen.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsOnOpen.toString()}`, action);
      state.status = 'connected';
      state.isReconnecting = false;
      state.error = null;
    });

    builder.addCase(actionEsOnClose, (state, action) => {
      console.log(`${actionEsOnClose.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsOnClose.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionEsOnClosed, (state, action) => {
      console.log(`${actionEsOnClosed.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsOnClosed.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionEsSend, (state, action) => {
      console.log(`${actionEsSend.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsSend.toString()}`, action);
    });

    builder.addCase(actionEsOnMessage, (state, action) => {
      console.log(`${actionEsOnMessage.toString()} ${action.payload.message}`);
      try {
        console.debug(`${actionEsOnMessage.toString()}`, JSON.parse(action.payload.message));
      } catch (e) {
        console.error('Failed to parse message', e);
      }
    });

    builder.addCase(actionEsOnError, (state, action) => {
      console.error(`${actionEsOnError.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionEsOnError.toString()}`, action);
      state.status = 'idle';
      state.error = action.payload;
    });
  },
});

startListening({
  matcher: isAnyOf(actionEsOnOpen, signUpApi.endpoints.postAuthenticate2FA.matchFulfilled),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const accessToken = selectAccessToken(state);
    await ensureAccessToken(listenerApi, accessToken);
  },
});

startListening({
  matcher: isAnyOf(actionEsOnOpen),
  effect: async (action, listenerApi) => {
    const wsStatus = selectWsEsStatus(listenerApi.getState());

    const resource = await checkAccessUiNavigation({ store: listenerApi as unknown as Store });
    const [defaultDomain] = getDefaults(resource);

    const forceLogin = action.type === actionEsOnOpen.type;
    const domain = skyRouter.getDomain() ?? defaultDomain ?? ApplicationDomain.Trading;
    const pathname = skyRouter.getPathname();

    if (!requiresLoginCommand(pathname) || wsStatus !== 'connected') {
      return;
    }

    listenerApi.dispatch(authActions.setAuthDomain({ domain }));
    await listenerApi.dispatch(loginDomain({ domain, forceLogin }));
  },
});

startListening({
  actionCreator: actionEsSend,
  effect: async (_, api) => {
    const accessToken = selectAccessToken(api.getState());
    await ensureAccessToken(api, accessToken);
  },
});

export const wsEsActions = wsEsSlice.actions;
export default wsEsSlice.reducer;
