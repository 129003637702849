import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import type { ServerPrefix } from 'src/store/shared/server-mode/types';
import { ServerModeCommands, ServerModes } from 'src/store/shared/server-mode/types';

export interface ServerModeState {
  serverMode: ServerModes;
  shouldNotifyServerMode: boolean;
}

const initialState: ServerModeState = {
  serverMode: ServerModes.ReadWrite,
  shouldNotifyServerMode: false,
};

export function createServerModeSlice(prefix: ServerPrefix) {
  const allowedCommands = Object.values(ServerModeCommands);

  return createSlice({
    name: `${prefix}ServerMode`,
    initialState,
    reducers: {
      clearServerModeNotification: (state) => {
        state.shouldNotifyServerMode = false;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(actionOnMessage, (state, action) => {
        const { json, skipProcessing } = parseResponse(action.payload, allowedCommands);

        if (skipProcessing || !json) return;

        const { command } = json;

        if (command === ServerModeCommands.ServerMode) {
          const { mode } = json;
          const prevMode = state.serverMode;
          state.serverMode = mode;

          if (prevMode !== mode) {
            state.shouldNotifyServerMode = true;
          }
        }
      });
    },
  });
}
