import type React from 'react';

export enum ApplicationDomain {
  Loans = 'Loans',
  Retainers = 'Retainers',
  Admin = 'Admin',
  Profile = 'Profile',
  Trading = 'Trading',
}

export enum SkyRouterAction {
  Pop = 'POP',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export interface TypePage {
  key: string;
  url: string;
  label: string;
  icon: React.ReactNode;
}

export type TypeNavigation = Record<string, Record<string, TypePage>>;
