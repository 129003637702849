import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TradingApiCommands } from './types';
import type {
  TradingApiKeysBalance,
  TradingApiKeysState,
  TradingApiKeysToken,
  TokenValue,
} from './types';
import { actionEsOnMessage } from '../actions';
import { parseResponse } from 'src/store/utils';
import {
  listen,
  createApiKey,
  rtkTradingApiKey,
  pauseTask,
  resumeTask,
  cancelTask,
} from './actions';

const allowedCommands = Object.values(TradingApiCommands);

export const initialState: TradingApiKeysState = {
  error: null,
  requestStatus: null,
  balance: [
    /* {
      lastPrice: 1,
      quotePrice: 1,
      token: 'USD',
      type: '0',
      volume: 312312,
    },
    {
      lastPrice: 12,
      quotePrice: 12,
      token: 'BTC',
      type: '0',
      volume: 312312,
    },
    {
      lastPrice: 1,
      quotePrice: 1,
      token: 'USD',
      type: '0',
      volume: 0,
    }, */
  ],
  //TODO: cleanup mock data after implementing the real API
  list: [
    /*     { id: 23, name: 'ByBit', key: 'Ow4eF7QQ97y0PIIGBU', smallTokens: false },
    { id: 24, name: 'Binance', key: 'Ow4eF7QQ97y0PIIGBU', smallTokens: false }, */
  ],
  //TODO: cleanup mock data after implementing the real API
  tokens: [
    /*  {
      amount: 1.5,
      depthMinus: 0.15,
      depthPlus: 0.25,
      exchangedID: 23,
      price: 42000.5,
      quotes: ['USDT', 'USDC', 'USD'],
      spread: 0.002,
      ticker: 'BTC/USDT',
      volume: 150000,
    }, */
  ],
  //TODO: cleanup mock data after implementing the real API
  tasks: [
    /*    {
      id: 1,
      action: 0,
      type: 1,
      company: 'Company XYZ',
      exchange: { id: 19, name: 'ByBit', ticker: 'BYBIT' },
      pair: 'BTC/USD',
      lastPrice: 50000,
      priceTrigger: 49000,
      priceLimit: 51000,
      orderSize: 1000,
      period: '60 sec.',
      volume: 1600000,
      volumeExcuted: 1600000,
      status: 1,
      mode: 'C',
      quote: false,
      message: 'Waiting For Balance',
      state: 1,
      rights: true,
      token: 'BTC',
    },
    {
      id: 2,
      action: 1,
      type: 1,
      company: 'Company XYZ',
      exchange: { id: 19, name: 'Gate', ticker: 'GATE' },
      pair: 'SOL/USD',
      lastPrice: 0.033,
      priceTrigger: 0,
      priceLimit: 0.003,
      orderSize: 25,
      period: '60 sec.',
      volume: 1600000,
      volumeExcuted: 0,
      status: 2,
      mode: 'C',
      quote: false,
      message: 'Active',
      state: 0,
      rights: true,
      token: 'BTC',
    }, */
  ],
  selectedExchange: undefined,
  exchange: {},
  filteredTokens: [],
  searchTokenValue: '',
  fetching: false,
  options: [],
  selectedToken: null,
  hasSearched: false,
  activeListenSection: null,
};

const transformTokens = (tokens: TradingApiKeysToken[]) => {
  return tokens.flatMap((token) => {
    const [base] = token.ticker.split('/');
    return token.quotes.map((quote) => ({
      ...token,
      ticker: `${base}/${quote}`,
    }));
  });
};

const transformTokensToOptions = (tokens: TradingApiKeysToken[]) => {
  return tokens.flatMap((token) => {
    const [base] = token.ticker.split('/');
    return token.quotes.map((quote) => ({
      label: `${base}/${quote}`,
      value: `${base}/${quote}`,
      token: {
        ...token,
        ticker: `${base}/${quote}`,
      },
    }));
  });
};

const tradingApiKeysSlice = createSlice({
  name: 'tradingApiKeys',
  initialState,
  reducers: {
    onSelectedExchange: (state, action: PayloadAction<number | undefined>) => {
      state.selectedExchange = action.payload;
    },

    setActiveListenSection: (state, action: PayloadAction<string | null>) => {
      state.activeListenSection = action.payload;
    },

    // Preparing a token and pair for sending the listen command
    setFilteredTokens: (state, action: PayloadAction<TradingApiKeysToken[]>) => {
      state.filteredTokens = action.payload;
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setOptions: (state, action: PayloadAction<TokenValue[]>) => {
      state.options = action.payload;
    },
    setSelectedToken: (state, action: PayloadAction<TradingApiKeysToken | null>) => {
      state.selectedToken = action.payload;
    },
    setHasSearched: (state, action: PayloadAction<boolean>) => {
      state.hasSearched = action.payload;
    },
    transformAndSetOptions: (state) => {
      if (!state.tokens?.length) {
        state.options = [];
        return;
      }

      state.options = transformTokensToOptions(state.tokens);
    },

    searchTokens: (state, action: PayloadAction<string>) => {
      state.fetching = true;
      state.hasSearched = true;
      state.searchTokenValue = action.payload;

      const tokens = state.tokens ?? [];
      const transformedTokens = transformTokens(tokens);

      if (!action.payload.trim()) {
        state.filteredTokens = [];
        state.selectedToken = null;
        state.fetching = false;
        return;
      }

      state.filteredTokens = transformedTokens.filter((token) =>
        token.ticker.toLowerCase().includes(action.payload.toLowerCase()),
      );
    },
    filterOptions: (state, action: PayloadAction<string>) => {
      const searchValue = action.payload.toLowerCase();
      if (!searchValue) {
        if (!state.tokens?.length) {
          state.options = [];
          return;
        }
        state.options = transformTokensToOptions(state.tokens);
        return;
      }

      state.options = state.options.filter((option) =>
        option.label.toLowerCase().includes(searchValue),
      );
    },

    selectToken: (state, action: PayloadAction<{ value: string } | null>) => {
      if (!action.payload?.value || !state.tokens) {
        state.selectedToken = null;
        state.searchTokenValue = '';
        state.activeListenSection = null;
        return;
      }

      const [selectedBase, selectedQuote] = action.payload.value.split('/');

      const originalToken = state.tokens.find((token) => {
        const [base] = token.ticker.split('/');
        return base === selectedBase && token.quotes.includes(selectedQuote);
      });

      if (originalToken) {
        state.selectedToken = {
          ...originalToken,
          ticker: action.payload.value,
        };
        state.searchTokenValue = action.payload.value;
        state.activeListenSection = action.payload.value.toLowerCase();
      }
    },

    clearSelection: (state) => {
      state.selectedToken = null;
      state.searchTokenValue = '';
      state.filteredTokens = [];
      state.options = [];
      state.hasSearched = false;
      state.activeListenSection = null;
    },

    initializeTokens: (state) => {
      if (state.tokens?.length) {
        state.options = transformTokensToOptions(state.tokens);
      }
    },
  },
  extraReducers: (builder) => {
    // listen
    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.requestStatus = 'pending';
      }
      state.fetching = true;
    });

    builder.addCase(createApiKey.pending, (state) => {
      state.requestStatus = 'pending';
    });

    builder.addCase(pauseTask.pending, (state) => {
      state.requestStatus = 'pending';
    });
    builder.addCase(resumeTask.pending, (state) => {
      state.requestStatus = 'pending';
    });
    builder.addCase(cancelTask.pending, (state) => {
      state.requestStatus = 'pending';
    });

    // on WS message
    builder.addCase(actionEsOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `tradingApiKeys/processing ${actionEsOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === TradingApiCommands.Investor) {
        const { list } = json;
        state.list = list;
      }
      if (command === TradingApiCommands.Balance) {
        const { balance } = json as { balance: TradingApiKeysBalance };
        state.balance = balance.balance;
        state.requestStatus = 'success';
      }
      if (command === TradingApiCommands.Token) {
        const { tokens } = json;
        state.tokens = tokens;
        state.filteredTokens = transformTokens(tokens);
        if (state.selectedToken) {
          const updatedToken = state.filteredTokens.find(
            (token) => token.ticker.toLowerCase() === state.selectedToken?.ticker.toLowerCase(),
          );
          if (updatedToken) {
            state.selectedToken = updatedToken;
          }
        }
        state.requestStatus = 'success';
      }
      if (command === TradingApiCommands.Tasks) {
        const { tasks } = json;
        state.tasks = tasks;
        state.requestStatus = 'success';
      }
    });

    builder.addCase(listen.fulfilled, (state) => {
      state.fetching = false;
    });

    builder.addCase(listen.rejected, (state) => {
      state.fetching = false;
      state.options = [];
    });

    builder.addMatcher(rtkTradingApiKey.endpoints.getExchange.matchFulfilled, (state, action) => {
      state.exchange = action.payload;
    });
  },
});

export const tradingApiKeysActions = tradingApiKeysSlice.actions;
export default tradingApiKeysSlice.reducer;
