import { createSlice } from '@reduxjs/toolkit';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { resetStore } from 'src/store/actions';
import type { RequestStatus } from 'src/store/types';
import type { JsonArchivedLoan } from 'src/store/loans/types';
import type { JsonArchivedRetainer } from 'src/store/retainers/slice';
import type { searchArchivedLoans } from 'src/store/loans/actions';
import type { searchArchivedRetainers } from 'src/store/retainers/actions';
import type { GenericError } from 'src/store/utils/errors';

export interface ArchiveState {
  archivedItems: JsonArchivedLoan[] | JsonArchivedRetainer[];
  requestStatus: RequestStatus | null;
  error: GenericError | null;
  restoreStatus: RequestStatus | null;
}

export const initialState: ArchiveState = {
  archivedItems: [],
  requestStatus: null,
  error: null,
  restoreStatus: null,
};

export function createArchiveSlice({
  prefix,
  commands,
  searchAction,
}: {
  prefix: 'loans' | 'retainers';
  commands: {
    ArchivedItems: string;
    SearchArchivedItems: string;
    ShowItem: string;
  };
  searchAction: typeof searchArchivedLoans | typeof searchArchivedRetainers;
}) {
  const allowedCommands = [commands.ArchivedItems, commands.SearchArchivedItems, commands.ShowItem];

  return createSlice({
    name: `${prefix}/archive`,
    initialState,
    reducers: {
      setRestoreStatus(state, action: { payload: RequestStatus | null }) {
        state.restoreStatus = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(searchAction.pending, (state) => {
        state.requestStatus = 'pending';
        state.error = null;
      });

      builder.addCase(searchAction.rejected, (state) => {
        state.requestStatus = 'error';
      });

      builder.addCase(actionOnMessage, (state, action) => {
        const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

        if (skipProcessing || !json) return;

        const { command } = json;
        if (error) {
          state.error = error;
          state.requestStatus = 'error';
          return;
        }

        if (command === commands.ShowItem) {
          state.restoreStatus = 'success';
          return;
        }

        if (command === commands.ArchivedItems) {
          const { list } = json;
          state.archivedItems = list || [];
          state.requestStatus = 'success';
          state.error = null;
        }
      });

      builder.addCase(resetStore, () => {
        return initialState;
      });
    },
  });
}
