import { createSlice } from '@reduxjs/toolkit';
import type { JsonLogSuccess, LogsState } from 'src/store/shared/log/types';
import { LogsCommand } from 'src/store/shared/log/types';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { listen as loanListing } from 'src/store/loans/actions';
import { listen as retainerListing } from 'src/store/retainers/actions';
import { listen as adminListing } from 'src/store/admin/actions';

import { parseResponse } from 'src/store/utils';

export const initialState: LogsState = {
  error: null,
  requestStatus: null,
  list: [],
};

const allowedCommands = Object.values(LogsCommand);

const Logs = createSlice({
  name: 'logs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loanListing.pending, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.requestStatus = 'pending';
        state.list = [];
      }
    });

    builder.addCase(retainerListing.pending, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.requestStatus = 'pending';
        state.list = [];
      }
    });

    builder.addCase(adminListing.pending, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.requestStatus = 'pending';
        state.list = [];
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);
      console.log({ error });
      if (skipProcessing || !json) return;

      console.debug(`logs/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      if (error) {
        state.error = error;
        return;
      }

      const { command } = json;

      if (command === LogsCommand.Log) {
        const { list } = json as JsonLogSuccess;
        state.list = list;
        state.requestStatus = 'success';
      }
    });
  },
});

export const actionLogs = { ...Logs.actions };

export default Logs.reducer;
