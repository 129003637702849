import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import filter from 'lodash/filter';

import {
  createApiKey,
  createSubAccount,
  deleteApiKey,
  encryptedToken,
  linkApiKeyToProject,
  listen,
  rtkApiKey,
  unlinkApiKeyFromProject,
  updateLabelApiKey,
} from 'src/store/apiKeys/actions';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import type {
  ApiKeyItem,
  ApiKeysState,
  JsonApiKeyItem,
  OpenModalType,
} from 'src/store/apiKeys/types';
import { ApiCommands } from 'src/store/apiKeys/types';

const allowedCommands = Object.values(ApiCommands);

export interface JsonApiKeysResponse {
  list: ApiKeyItem[];
  command: 'APIKeys';
}

export const initialState: ApiKeysState = {
  error: null,

  isOpenModal: false,

  requestStatus: null,
  resRemoveApiKey: null,
  modalInitialValue: undefined,

  list: [],
  exchange: {},
  reqApiKey: null,
  reqGetEncryptedToken: null,
  selectedExchange: undefined,

  reqLinkApi: null,
  typeModal: undefined,
};

const copyIdKey = (key: string) => {
  navigator.clipboard.writeText(key).catch((e) => {
    console.error(e);
  });
};

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalType>) => {
      state.isOpenModal = true;
      state.reqApiKey = null;
      if (action?.payload.initialValues) {
        state.modalInitialValue = action.payload.initialValues;
        state.typeModal = action.payload.type;
      } else {
        state.typeModal = 'new';
      }
    },
    closeModal: (state) => {
      state.isOpenModal = false;
      state.modalInitialValue = undefined;
      state.selectedExchange = undefined;
      state.typeModal = undefined;
    },
    onSelectedExchange: (state, action: PayloadAction<number>) => {
      state.selectedExchange = action.payload;
    },
  },
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall } = action.meta.arg;
      if (isInitCall) {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(deleteApiKey.pending, (state) => {
      state.resRemoveApiKey = 'pending';
    });

    builder.addCase(createApiKey.pending, (state) => {
      state.reqApiKey = 'pending';
    });

    builder.addCase(updateLabelApiKey.pending, (state) => {
      state.reqApiKey = 'pending';
    });

    builder.addCase(encryptedToken.pending, (state) => {
      state.reqGetEncryptedToken = 'pending';
    });

    builder.addCase(linkApiKeyToProject.pending, (state) => {
      state.reqLinkApi = 'pending';
    });

    builder.addCase(unlinkApiKeyFromProject.pending, (state) => {
      state.reqLinkApi = 'pending';
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse<
        | {
            id: string;
            command: 'ok';
            okCommand: ApiCommands;
            result: string;
          }
        | JsonApiKeysResponse
      >(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`apiKeys/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === ApiCommands.APIKeys) {
        const { list } = json;
        state.list = list;
        state.requestStatus = 'success';
      }

      if (command === 'ok') {
        const { okCommand, result } = json;

        if ([ApiCommands.CreateAPIKey, ApiCommands.UpdateAPIKey].includes(okCommand)) {
          const objRes = JSON.parse(result) as ApiKeyItem;
          const prevStateList = filter(state.list, (item: ApiKeyItem) => item.ID !== objRes.ID);
          state.list = [...prevStateList, objRes] as JsonApiKeyItem[];
        }

        switch (okCommand) {
          case ApiCommands.GetAPIKey:
            copyIdKey(json.id);
            state.reqGetEncryptedToken = 'success';
            break;
          case ApiCommands.CreateAPIKey:
          case ApiCommands.UpdateAPIKey:
          case ApiCommands.LinkLoan:
          case ApiCommands.UnlinkLoan:
            state.reqApiKey = 'success';
            state.modalInitialValue = undefined;
            state.isOpenModal = false;
            state.selectedExchange = undefined;
            break;
          case ApiCommands.DeleteAPIKey:
            if (json.id) {
              state.list = [
                ...filter(state.list, (item: ApiKeyItem) => item.AccountID !== json.id),
              ];
            }
            state.resRemoveApiKey = 'success';
            break;
          default:
        }
      }
    });

    builder.addMatcher(rtkApiKey.endpoints.getExchange.matchFulfilled, (state, action) => {
      state.exchange = action.payload;
    });
  },
});

export const apiKeysAction = {
  ...apiKeysSlice.actions,
  listen,
  encryptedToken,
  createApiKey,
  updateLabelApiKey,
  linkApiKeyToProject,
  unlinkApiKeyFromProject,
  createSubAccount,
  deleteApiKey,
};

export default apiKeysSlice.reducer;
