import { createSlice } from '@reduxjs/toolkit';

import { actionOnMessage } from 'src/store/ws-loans/actions';

import { parseResponse } from 'src/store/utils';
import { listen } from './actions';
import type { ArbitrageState, JsonArbitrageDetailsSuccess, JsonArbitrageSuccess } from './types';
import { ArbitrageCommands } from './types';

const allowedCommands = Object.values(ArbitrageCommands);

export const initialState: ArbitrageState = {
  list: [],
  error: null,
  details: null,
  requestStatus: null,
  detailsRequestStatus: null,
};

const arbitrageSlice = createSlice({
  name: 'arbitrage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`arbitrage/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command } = json;

      if (error) {
        state.error = error;
        state.requestStatus = 'error';
        return;
      }

      switch (command) {
        case ArbitrageCommands.Arbitrage: {
          const { list } = json as JsonArbitrageSuccess;
          state.requestStatus = 'success';
          state.list = list;
          break;
        }
        case ArbitrageCommands.ArbitrageDetails: {
          const { id, Accounts, settings, totalFeePaid } = json as JsonArbitrageDetailsSuccess;
          state.requestStatus = 'success';
          state.details ??= {};
          // potentially could lead to memory leak, if application runs for a long time and user opens a lot of arbitrage details
          // but seems like the possibility is quite low
          // possible solution is to keep opened arbitrage details in the store and purge details based on opened arbitrage details
          state.details[id] = { id, list: Accounts, settings, totalFeePaid };
          break;
        }
        default:
      }
    });
  },
});

export const arbitrageActions = {
  listen,
};

export default arbitrageSlice.reducer;
