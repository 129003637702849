import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import type { RootState } from 'src/store';
import { connect, disconnect, send } from '../middleware/redux-websocket';

import { prefix } from './middleware';
import type { WsCommand, WsError } from 'src/store/ws-loans/types';
import { ALLOW_WS_OVERRIDE, MM_WS_QS_KEY, MM_WS_SS_KEY, MM_WS_URL } from 'src/constants';
import isString from 'lodash/isString';
import { AuthCommands } from 'src/store/auth/types';

export const actionConnect = createAction<object>(`${prefix}::CONNECT`);
export const actionOnOpen = createAction<object>(`${prefix}::OPEN`);
export const actionDisconnect = createAction<object>(`${prefix}::DISCONNECT`);
export const actionOnClose = createAction<object>(`${prefix}::CLOSE`);
export const actionOnClosed = createAction<object>(`${prefix}::CLOSED`);
export const actionSend = createAction<object>(`${prefix}::SEND`);
export const actionOnMessage = createAction<{ message: string } & Record<string, any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
  `${prefix}::MESSAGE`,
);
export const actionOnBroken = createAction<object>(`${prefix}::BROKEN`);
export const actionOnReconnectBegin = createAction<object>(`${prefix}::BEGIN_RECONNECT`);
export const actionOnReconnectAttempt = createAction<object>(`${prefix}::RECONNECT_ATTEMPT`);
export const actionOnReconnected = createAction<object>(`${prefix}::RECONNECTED`);
export const actionOnError = createAction<WsError>(`${prefix}::ERROR`);

export const wsLoansConnect = () => {
  const query = qs.parse(document.location.search, { ignoreQueryPrefix: true });
  const qsVal = isString(query[MM_WS_QS_KEY]) ? (query[MM_WS_QS_KEY] as string) : undefined;

  if (qsVal && ALLOW_WS_OVERRIDE === '1') {
    if (qsVal !== '0') {
      sessionStorage.setItem(MM_WS_SS_KEY, qsVal);
      console.log(`Loans WS URL has been replaced with query param: ${qsVal}`);
      return connect(qsVal, prefix);
    }
    sessionStorage.removeItem(MM_WS_SS_KEY);
  }

  const storedWs = sessionStorage.getItem(MM_WS_SS_KEY);
  if (storedWs && ALLOW_WS_OVERRIDE === '1') {
    return connect(storedWs, prefix);
  }

  return connect(MM_WS_URL, prefix);
};

export const wsLoansDisconnect = createAsyncThunk('ws/disconnect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsLoans.status;
  if (wsStatus === 'connected') {
    thunkAPI.dispatch(wsLoansSend({ command: AuthCommands.Logout }));
    disconnect(prefix);
  }
});

export const wsLoansSend = (message: WsCommand) => send(message, prefix);

export const subscribeWsData = createAsyncThunk('ws/connect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsLoans.status;

  if (wsStatus === 'idle') {
    return thunkAPI.dispatch(wsLoansConnect());
  }
});
