import type { IsoDateString } from 'src/utils/formatting/dates';
import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export interface LogsState {
  error: null | GenericError;
  requestStatus: null | RequestStatus;
  list: LogItem[];
}

export interface LogItem {
  ID?: number;
  key?: number;
  Date: IsoDateString;
  Message: string;
  Author: string;
}

export interface JsonLogSuccess {
  command: 'Log';
  list: LogItem[];
}

export enum LogsCommand {
  Log = 'Log',
}
