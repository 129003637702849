import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation, selectNavigationIsLoading } from 'src/store/navigation/selector';
import { useCerbos } from '@cerbos/react';
import { useEffect } from 'react';
import { navigationPermissionsActions } from 'src/store/navigation/slice';
import { UINavigationCheck } from 'src/utils/cerbos/UINavigation';

export const useCheckNavigationAccess = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectNavigation);

  const isLoading = useSelector(selectNavigationIsLoading);
  const client = useCerbos();

  useEffect(() => {
    const fetchUINavigationPermissions = async () => {
      if (client) {
        try {
          const resource = await client.checkResource({
            ...UINavigationCheck,
          });
          dispatch(
            navigationPermissionsActions.setData({
              data: resource.allowedActions(),
            }),
          );
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (!data && !isLoading) {
      dispatch(navigationPermissionsActions.setLoading('pending'));
      void fetchUINavigationPermissions();
    }
  }, [client, data, isLoading, dispatch]);

  return {
    data,
    isLoading: !data || isLoading,
  };
};
