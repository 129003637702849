import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import type { RootState } from 'src/store';
import { connect, disconnect, send } from '../middleware/redux-websocket';
import { prefix } from './middleware';
import { ES_WS_URL, ES_WS_SS_KEY, ES_WS_QS_KEY, ALLOW_WS_OVERRIDE } from 'src/constants';
import isString from 'lodash/isString';
import type { WsCommand } from 'src/store/ws-loans/types';
import { AuthCommands } from 'src/store/auth/types';

export const actionEsConnect = createAction<object>(`${prefix}::CONNECT`);
export const actionEsOnOpen = createAction<object>(`${prefix}::OPEN`);
export const actionEsDisconnect = createAction<object>(`${prefix}::DISCONNECT`);
export const actionEsOnClose = createAction<object>(`${prefix}::CLOSE`);
export const actionEsOnClosed = createAction<object>(`${prefix}::CLOSED`);
export const actionEsSend = createAction<object>(`${prefix}::SEND`);
export const actionEsOnMessage = createAction<{ message: string } & Record<string, any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
  `${prefix}::MESSAGE`,
);
export const actionEsOnBroken = createAction<object>(`${prefix}::BROKEN`);
export const actionEsOnReconnectBegin = createAction<object>(`${prefix}::BEGIN_RECONNECT`);
export const actionEsOnReconnectAttempt = createAction<object>(`${prefix}::RECONNECT_ATTEMPT`);
export const actionEsOnReconnected = createAction<object>(`${prefix}::RECONNECTED`);
// TODO: add error typing
export const actionEsOnError = createAction<object>(`${prefix}::ERROR`);

export const wsEsConnect = () => {
  const query = qs.parse(document.location.search, { ignoreQueryPrefix: true });
  const qsVal = isString(query[ES_WS_QS_KEY]) ? (query[ES_WS_QS_KEY] as string) : undefined;

  if (qsVal && ALLOW_WS_OVERRIDE === '1') {
    if (qsVal !== '0') {
      sessionStorage.setItem(ES_WS_SS_KEY, qsVal);
      console.log(`Es WS URL has been replaced with query param: ${qsVal}`);
      return connect(qsVal, prefix);
    }
    sessionStorage.removeItem(ES_WS_SS_KEY);
  }

  const storedWs = sessionStorage.getItem(ES_WS_SS_KEY);
  if (storedWs && ALLOW_WS_OVERRIDE === '1') {
    return connect(storedWs, prefix);
  }

  return connect(ES_WS_URL, prefix);
};

export const wsEsDisconnect = createAsyncThunk('ws/es-disconnect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsEs.status;
  if (wsStatus === 'connected') {
    thunkAPI.dispatch(wsEsSend({ command: AuthCommands.Logout }));
    disconnect(prefix);
  }
});
// TODO: add message typing
export const wsEsSend = (message: WsCommand) => send(message, prefix);

export const subscribeWsEsData = createAsyncThunk('ws/es-connect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsEs.status;

  if (wsStatus === 'idle') {
    thunkAPI.dispatch(wsEsConnect());
  }
});
