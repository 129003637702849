import { createArchiveSlice } from 'src/store/shared/archive/factory';
import { searchArchivedRetainers } from 'src/store/retainers/actions';

export enum RetainerArchiveCommands {
  ArchivedRetainers = 'ArchivedRetainers',
  SearchArchivedRetainers = 'SearchArchivedRetainers',
  ShowRetainer = 'ShowRetainer',
}

const slice = createArchiveSlice({
  prefix: 'retainers',
  commands: {
    ArchivedItems: RetainerArchiveCommands.ArchivedRetainers,
    SearchArchivedItems: RetainerArchiveCommands.SearchArchivedRetainers,
    ShowItem: RetainerArchiveCommands.ShowRetainer,
  },
  searchAction: searchArchivedRetainers,
});

export const retainersArchiveActions = slice.actions;
export default slice.reducer;
