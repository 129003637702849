import { createAsyncThunk } from '@reduxjs/toolkit';
import { tradingApiKeysActions } from './slice';
import { TradingApiCommands, TradingApiKeyEndpoints } from './types';
import type { AddApiKeyPayload, AddTaskPayload, ExchangeObjItems } from './types';
import { wsEsSend } from '../actions';
import omit from 'lodash/omit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_URL, ADMIN_X_TOKEN } from 'src/constants';

export interface ListenTradingApiKeysPayload {
  section?: string;
  token?: string;
  isInitCall: boolean;
}

export const listen = createAsyncThunk(
  'tradingApiKeys/listen',
  (payload: ListenTradingApiKeysPayload, thunkApi) => {
    return thunkApi.dispatch(
      wsEsSend({
        command: 'Listen',
        ...omit(payload, 'isInitCall'),
      }),
    );
  },
);

export const createApiKey = createAsyncThunk(
  'tradingApiKeys/createApiKey',
  (data: AddApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsEsSend({
        command: TradingApiCommands.AddAPIKey,
        ...data,
      }),
    ),
);

export const addTask = createAsyncThunk(
  'tradingApiKeys/addTask',
  (data: AddTaskPayload, thunkApi) =>
    thunkApi.dispatch(
      wsEsSend({
        command: TradingApiCommands.AddTask,
        ...data,
      }),
    ),
);

export const pauseTask = createAsyncThunk('tradingApiKeys/pauseTask', (id: number, thunkApi) =>
  thunkApi.dispatch(
    wsEsSend({
      command: TradingApiCommands.PauseTask,
      id,
    }),
  ),
);

export const resumeTask = createAsyncThunk('tradingApiKeys/resumeTask', (id: number, thunkApi) =>
  thunkApi.dispatch(
    wsEsSend({
      command: TradingApiCommands.ResumeTask,
      id,
    }),
  ),
);

export const cancelTask = createAsyncThunk('tradingApiKeys/cancelTask', (id: number, thunkApi) =>
  thunkApi.dispatch(
    wsEsSend({
      command: TradingApiCommands.CancelTask,
      id,
    }),
  ),
);

export const searchTokens = createAsyncThunk(
  'tradingApiKeys/searchTokensAsync',
  async (value: string, { dispatch }) => {
    dispatch(tradingApiKeysActions.searchTokens(value));

    if (value.trim()) {
      await dispatch(listen({ token: value, isInitCall: true }));
    }
  },
);

export const rtkTradingApiKey = createApi({
  reducerPath: 'tradingApiKeys/rtk',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_URL,
    prepareHeaders: (headers) => {
      headers.set('x-access-token', ADMIN_X_TOKEN);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getExchange: build.mutation<ExchangeObjItems, void>({
      query: () => ({
        url: TradingApiKeyEndpoints.Exchange,
        method: 'GET',
        params: { vc: 1 },
      }),
    }),
  }),
});

export const { useGetExchangeMutation } = rtkTradingApiKey;

export const apiKeysAction = {
  ...tradingApiKeysActions,
  listen,
  createApiKey,
  searchTokens,
  addTask,
  pauseTask,
  resumeTask,
  cancelTask,
};
