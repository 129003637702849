import type { GenericError } from 'src/store/utils/errors';
import type { LiquidityMap } from 'src/store/shared/kpi/types';
import type { JsonLoanExchangeExtended } from 'src/store/loans/exchanges/types';
import type { RequestStatus } from 'src/store/types';
import type { ActualDepth, DepthDataMap } from 'src/store/loans/types';
import { LoanCommands } from 'src/store/loans/types';

export enum LoanLiquidityCommands {
  LoanLiquidity = LoanCommands.LoanLiquidity,
}

export interface JsonLoanLiquiditySuccess {
  command: LoanLiquidityCommands.LoanLiquidity;
  id: number;
  liquidity: JsonLoanLiquidity[];
  details: JsonLoanDetails[];
}

export interface JsonLoanDetails {
  tokens: string;
  name: string;
  company: string;
  started: string;
  ended: string;
  targetDelta: number;
  strikePrice: number;
  amounts: {
    baseAmount: number;
    quoteAmount: number;
  };
  paybackAmounts: {
    baseAmount: number;
    quoteAmount: number;
    residualAmount: number;
  };
  exchanges: number[];
  pairs: string[];
  funds: {
    Amount: number;
    Token: string;
    Author: string;
  };
}

export interface JsonLoanLiquidity {
  ExchangeID: number;
  Pair: string;
  AccountID: string;
  AccountName: string;
  Liquidity: LiquidityMap;
  ActualLiquidity: Record<ActualDepth, number>;
}

export type LoanLiquidityCache = Record<string, JsonLoanLiquidity[]>;

export type LoanLiquidityViewFilters = 'no' | 'supportedPairs';
export type LoanLiquidityAggregatedValuesFilter = 'fromServer' | 'fromBots';

export interface LoanLiquidityState {
  requestStatus: RequestStatus;
  error: null | GenericError;
  list: JsonLoanLiquidity[] | null;
  listCache: LoanLiquidityCache;
  viewFilter: LoanLiquidityViewFilters;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;
}

export interface AccountLiquidity {
  accountId: string;
  accountName: string;
  volumeLocal: number;
  volumeUTC: number;
  accountVolumeByDateLocal: [string, number][];
  accountVolumeByDateUTC: [string, number][];

  avgAccountLiquidity: Record<ActualDepth, number>;

  avgAccountLiquidityByDate: Record<ActualDepth, [string, number][]>;

  currentAccountLiquidity: Record<ActualDepth, number>;

  actualAccountLiquidity: Record<ActualDepth, number>;
}

export type AccountLiquidityTree = AccountLiquidity &
  Pick<JsonLoanExchangeExtended, 'avgMarketLiquidity' | 'kpi' | 'exchange'> & {
    key: string;
    depthData: DepthDataMap;
  };

export interface JsonLoanLiquidityExtended extends JsonLoanExchangeExtended {
  liquidityByAccount: AccountLiquidity[];
  botsVolumeLocal: number;
  botsVolumeUTC: number;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;

  avgBotsLiquidity: Record<ActualDepth, number>;

  avgBotsLiquidityByDate: Record<ActualDepth, [string, number][]>;

  currentBotsLiquidity: Record<ActualDepth, number>;

  actualBotsLiquidity: Record<ActualDepth, number>;

  marketDepthData: DepthDataMap;
}

export interface JsonLoanLiquidityExtendedTree extends JsonLoanLiquidityExtended {
  key: string;
  children: (AccountLiquidityTree & { key: string })[];
}
