export const requiresLoginCommand = (pathname: string) => {
  return ![
    '/admin/token-mapping',
    '/admin/users',
    '/admin/roles',
    '/signin',
    '/signin/2fa',
    '/signin/recovery',
    '/signin/password-reset',
    '/signout',
    '/signup',
    '/signup/confimation',
    '/signup/2fa',
    '/profile',
    '/profile/2fa',
    '/cookies',
    '/terms',
  ].some((path) => pathname.toLowerCase().startsWith(path));
};
