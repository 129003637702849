import type { Middleware } from '@reduxjs/toolkit';
import reduxWebsocket from '../middleware/redux-websocket';

export const prefix = 'LOANS_WS';

const wsLoansMiddleware: Middleware = reduxWebsocket({
  prefix,
  reconnectOnClose: false,
  dateSerializer: (date: Date) => date.toISOString(),
});

export default wsLoansMiddleware;
