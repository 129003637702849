import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';

export enum TradingApiCommands {
  Investor = 'Investor',
  Balance = 'Balance',
  Token = 'Token',
  Error = 'Error',
  AddAPIKey = 'AddAPIKey',
  AddTask = 'AddTask',
  Tasks = 'Tasks',
  PauseTask = 'PauseTask',
  ResumeTask = 'ResumeTask',
  CancelTask = 'CancelTask',
}

export enum TaskTypes {
  TWAP = 1,
  Adaptive = 2,
  VWAP = 3,
}

export enum TaskActions {
  Buy = 0,
  Sell = 1,
}

export enum TaskRandomizeValues {
  Disabled = 0,
  Enabled = 1,
}

export enum TaskStatus {
  Invalid = -1, // The status of the task is undefined
  Cancelled = 0, // The task has been canceled
  Active = 1, // Task is currently active, waiting for conditions to be met
  Executed = 5, // Task is running right now
  Paused = 8, // The task is paused
  Scheduled = 9, // Task execution is paused
  Done = 10, // Task successfully completed
}

export enum TaskState {
  Paused = 0,
  Active = 1,
}

export interface AddTaskPayload {
  type: TaskTypes;
  action: TaskActions;
  priceLimit: number;
  volume: number;
  orderSize: number;
  orderPeriod: number;
  randomize: TaskRandomizeValues;
}

export enum TradingApiKeyEndpoints {
  Exchange = '/me/exchanges',
}

export type WsResponse = JsonTradingApiKeysResponse;

export interface TradingBalance {
  lastPrice: number;
  quotePrice: number;
  token: string;
  type: string;
  volume: number;
}

export interface TradingBalanceList extends TradingBalance {
  volumeUSD: number;
}

export interface TradingApiKeysBalance {
  account: number;
  balance: TradingBalance[];
}

export interface TradingApiKeysToken {
  amount: number;
  depthMinus: number;
  depthPlus: number;
  exchangedID: number;
  price: number;
  quotes: string[];
  spread: number;
  ticker: string;
  volume: number;
}

export interface JsonTradingApiKeysResponse {
  list: TradingApiKeyItem[];
  balance: TradingApiKeysBalance;
  command: TradingApiCommands;
  tokens: TradingApiKeysToken[];
}

export interface TradingApiKeyItem {
  id: number;
  key: string;
  name: string;
  smallTokens: boolean;
}

export interface ExchangeItem {
  id: string | number | symbol;
  name: string;
  comment: string | null;
  video: string | null;
  ips: string | null;
  pub?: { length: number; mask: RegExp };
  secret?: { length: number; mask: RegExp };
  passphrase?: { label?: string; numbersOnly?: boolean; optional?: boolean };
  crypted: boolean;
}

export type ExchangeObjItems = Record<string, ExchangeItem>;

export interface InitialNewValuesApiKey {
  AccountID: string;
  ID: number;
  exchangeID: number;
}

export interface TradingApiKeysState {
  error: null | GenericError;
  requestStatus: RequestStatus;
  list: TradingApiKeyItem[];
  exchange: ExchangeObjItems;
  selectedExchange: number | undefined;
  balance: TradingBalance[] | null;
  tokens: TradingApiKeysToken[] | null;
  filteredTokens: TradingApiKeysToken[];
  searchTokenValue: string;
  fetching: boolean;
  options: TokenValue[];
  selectedToken: TradingApiKeysToken | null;
  hasSearched: boolean;
  tasks: JsonTradingPair[];
  activeListenSection: string | null;
}

export interface TokenValue {
  label: string;
  value: string;
  token: TradingApiKeysToken;
}

export interface AddApiKeyPayload {
  apiKey: string;
  apiSecret: string;
  exchangeID: number;
  optional?: string;
  passPhrase?: string;
}

export interface JsonTradingPair {
  id: number;
  action: TaskActions;
  type: TaskTypes;
  company: string;
  exchange?: JsonExchange;
  pair: string;
  lastPrice: number;
  priceTrigger: number;
  priceLimit: number;
  orderSize: number;
  period: string;
  volume: number;
  volumeExcuted: number;
  status: number;
  mode: string;
  quote: false;
  message: string;
  state: TaskState;
  rights: boolean;
  token: string;
  // TODO: remove this
}
