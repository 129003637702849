import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RequestStatus } from 'src/store/types';

export interface NavigationState {
  data: string[] | null;
  isLoading: RequestStatus;
}

export const initialState: NavigationState = {
  data: null,
  isLoading: null,
};

export const navigationPermissionsSlice = createSlice({
  name: 'navigationPermissions',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ data: NavigationState['data'] }>) => {
      state.data = action.payload.data;
      state.isLoading = 'success';
    },
    setLoading: (state, action: PayloadAction<RequestStatus>) => {
      state.isLoading = action.payload;
    },
  },
});

export const navigationPermissionsActions = {
  ...navigationPermissionsSlice.actions,
};

export default navigationPermissionsSlice.reducer;
