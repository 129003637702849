import { version } from '../package.json';

export const PACKAGE_VERSION = version;
export const AUTH_BASE_URL = import.meta.env.VITE_AUTH_API_URL || 'http://localhost:3000';
export const CERBOS_API_URL = import.meta.env.VITE_CERBOS_API_URL || 'http://localhost:3592';
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL || 'http://localhost';
export const ADMIN_X_TOKEN = import.meta.env.VITE_ADMIN_X_TOKEN || '';
export const DEX_API_URL = import.meta.env.VITE_DEX_API_URL || 'http://localhost';
export const LINEAR_API_KEY = import.meta.env.VITE_LINEAR_API_KEY || '';
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1.0;
export const MM_WS_URL = import.meta.env.VITE_LOANS_WS_URL || 'ws://localhost';
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const MM_WS_SS_KEY = import.meta.env.VITE_LOANS_WS_SS_KEY || 'WS_URL_OVERRIDE';
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const MM_WS_QS_KEY = import.meta.env.VITE_LOANS_WS_QS_KEY || 'ws';
export const ALLOW_WS_OVERRIDE = import.meta.env.VITE_ALLOW_WS_OVERRIDE;
export const IS_AUTHZ_NAVIGATION_ENABLED = true;
export const EMPTY_ARRAY = [];
export const ES_WS_URL = import.meta.env.VITE_ES_WS_URL || 'ws://localhost';
export const ES_WS_SS_KEY = import.meta.env.VITE_ES_WS_SS_KEY || '';
export const ES_WS_QS_KEY = import.meta.env.VITE_ES_WS_QS_KEY || '';
